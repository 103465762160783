




































import { defineComponent } from '@vue/composition-api';
import { AuthLogin, getMe, login } from '@/Auth';
import serialize from '@/helpers/serializeForm';

export default defineComponent({
  name: 'Login',
  setup(props, { root }) {
    function onSubmit(event: MouseEvent) {
      const target = event.target as HTMLFormElement;
      const data = serialize<AuthLogin>(target);
      login(data)
        .then(() => {
          getMe()
            .then(() => {
              root.$router.push({ name: 'Companies' });
            })
            .catch(() => {
              root.$bvToast.toast('Ошибка при получении данных, попробуйте снова', {
                title: 'Ошибка',
              });
            });
        })
        .catch((err) => {
          console.log('err.response.status', err.response.status);
          if (err.response.status === 401) {
            root.$bvToast.toast('Не верный логин или пароль', { title: 'Ошибка авторизации' });
          }
        });
    }

    return {
      onSubmit,
    };
  },
});
