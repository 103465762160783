<template>
  <div class="d-flex justify-content-center align-items-center h-100 flex-column">
    <h2>404 Страница не найдена</h2>
    <router-link to="/">На главную</router-link>
  </div>
</template>

<script>
export default {
  name: 'Page404',
};
</script>

<style scoped></style>
