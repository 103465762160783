import { get } from 'lodash';

export function createTranslate(translate: Record<string, any>) {
  return function (key: any, subkey?: string) {
    if (typeof key === 'string') {
      return subkey ? get(translate, subkey)[key] : translate[key];
    }
    console.warn(`Not found translate ${key}`);
    return '';
  };
}
