






































import Vue from 'vue';
import { defineComponent, ref, PropType, computed } from '@vue/composition-api';
import { AnswerModel, QuestionModel } from '@/api/models';
import { getYoutubeVideoUrl } from '@/helpers';

export default defineComponent({
  name: 'PublicTestQuestion',
  props: {
    answer: {
      type: Object as PropType<AnswerModel>,
    },
    question: {
      type: Object as PropType<QuestionModel>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedAnswer = ref();

    const isAnswered = computed(() => !!props.answer?.id);
    const videoSrc = computed(() => getYoutubeVideoUrl(props.question.video_url));

    if (props.answer) {
      selectedAnswer.value = props.answer.id;
    }

    function onToAnswer() {
      const answer = props.question.answers.find(({ id }) => id === selectedAnswer.value);
      Vue.set(props.question, 'answer', answer);
      emit('answered');
    }

    return {
      isAnswered,
      selectedAnswer,

      videoSrc,

      onToAnswer,
    };
  },
});
