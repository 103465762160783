





































import {
  defineComponent,
  computed,
  ref,
  watch,
  nextTick,
  onMounted,
  watchEffect,
} from '@vue/composition-api';
import { BIconChevronLeft, BIconChevronRight } from 'bootstrap-vue';
import { Test, TestSession } from '@/api/models';

export default defineComponent({
  name: 'UserTest',
  components: {
    BIconChevronLeft,
    BIconChevronRight,
  },
  setup(props, { root }) {
    const selectedQuestion = ref();

    const test = new Test({ id: Number(root.$route.params.test_id) });
    // eslint-disable-next-line @typescript-eslint/camelcase
    const session = new TestSession({ test_id: Number(test.model.value.id) });

    const options = computed(() => {
      return test.model.value?.questions?.map((question, index) => ({
        value: index + 1,
        text: `Вопрос №${index + 1}`,
      }));
    });

    const question = computed(() => test.model.value?.questions?.[selectedQuestion.value - 1]);
    const answer = computed(() =>
      session.model.value?.answers?.find((item) => item.question_id === question.value?.id)
    );
    const nextIndexQuestion = computed(() => {
      const answeredQuestionsIds = session.model.value?.answers?.map((item) => item.question_id);
      const index = test.model.value?.questions?.findIndex(
        (item) => !answeredQuestionsIds?.includes(item.id)
      );
      return index !== undefined ? Number(index) + 1 : -1;
    });

    const isCompletedTest = computed(() => {
      const answeredLength = session.model.value?.answers?.length;
      if (answeredLength) return answeredLength === test.model.value?.questions?.length;
      return false;
    });

    function onAnswered() {
      nextTick().then(() => {
        if (nextIndexQuestion.value) {
          root.$router.push({
            name: 'UserTestQuestion',
            params: { questionIndex: String(nextIndexQuestion.value) },
          });
        }
      });
      // return Number(options.value?.length) - selectedQuestion.value;
    }

    watch(selectedQuestion, (val: number, prev: number) => {
      if (val && prev !== val) {
        root.$router
          .push({
            name: 'UserTestQuestion',
            // eslint-disable-next-line @typescript-eslint/camelcase
            params: { ...root.$route.params, questionIndex: String(selectedQuestion.value) },
          })
          .catch((e) => e);
      }
    });

    onMounted(async () => {
      selectedQuestion.value = Number(root.$route.params.questionIndex) || 1;
      test.fetch().then((testResponse) => {
        if (!testResponse.test_session) {
          root.$bvModal
            .msgBoxConfirm('Начать прохождение тестирования? Тест можно пройти только один раз.', {
              okTitle: 'Начать',
              cancelTitle: 'Отмена',
              okVariant: 'primary',
            })
            .then((res) => {
              if (res) {
                session.save();
              } else {
                root.$router.push({ name: 'UserTests' });
              }
            })
            .catch(() => {
              root.$router.push({ name: 'UserTests' });
            });
        } else {
          session.model.value.id = test.model.value.test_session?.id;
          session.fetch();
        }
      });
    });

    watchEffect(() => {
      if (isCompletedTest.value) {
        session.close().then(() => {
          root.$bvModal
            .msgBoxConfirm('Поздравляем, тестирование окончено!', {
              okTitle: 'К списку тестов',
              cancelTitle: 'Остаться',
            })
            .then((res) => {
              if (res) {
                root.$router.push({ name: 'UserTests' });
              }
            });
        });
      }
    });

    return {
      answer,
      selectedQuestion,
      options,

      test: test.model,
      onAnswered,

      question,
      session,
    };
  },
});
