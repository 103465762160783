import { ref, computed, Ref } from '@vue/composition-api';

type Me = {
  id: number;
};

const isAuth = computed(() => Boolean(Me.value?.id));
const Me: Ref<null | Me> = ref(null);

export { Me, isAuth };
