



























import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import { getYoutubeVideoUrl } from '@/helpers';
import { Videos } from '@/api/collections';
import { VideoModel } from '@/api/models';

export default defineComponent({
  name: 'PublicVideos',
  setup() {
    const videos = new Videos();
    videos.fetch({}, true);

    const selectedVideo: Ref<VideoModel | undefined> = ref();
    const videoSrc = computed(() => getYoutubeVideoUrl(selectedVideo.value?.url));

    function onSelectVideo(video: VideoModel) {
      selectedVideo.value = video;
    }

    return {
      videoSrc,
      videos,
      selectedVideo,

      onSelectVideo,
    };
  },
});
