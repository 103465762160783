























import { defineComponent, computed } from '@vue/composition-api';
import { Companies } from '@/api/collections';

export default defineComponent({
  name: 'Companies',
  setup() {
    const companies = new Companies();
    companies.fetch();

    const adminCompanies = computed(() =>
      companies.items.value.filter((company) => company.users?.[0].pivot.admin)
    );

    return {
      adminCompanies,
      companies: companies.items,
      isLoading: companies.isLoading,
    };
  },
});
