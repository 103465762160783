import axios, { AxiosResponse } from 'axios';
import { clearToken, getToken, setToken } from './token';
import { startTimeoutRefreshToken } from './refreshToken';
import { isAuth, Me } from '@/store/auth';

const config = {
  baseURL: process.env.VUE_APP_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const instance = axios.create(config);

let unauthorizedAction: Function;
function callUnauthorizedAction(err: { response: AxiosResponse }) {
  if (err.response.status === 401) {
    clearToken();
    Me.value = null;
    unauthorizedAction && unauthorizedAction();
  }
}
function registerUnauthorizedAction(callback: Function) {
  unauthorizedAction = callback;
}

export type AuthRegister = {
  email: string;
  password: string;
  company_title: string;
  company_domain: string;
  name?: string;
  surname?: string;
  patronymic?: string;
  job_title?: string;
  organization?: string;
};

export type AuthLogin = {
  email: string;
  password: string;
};

function replaceEmptyToNull(data: Record<string, any>) {
  for (const key in data) {
    if (data[key] === '') data[key] = null;
  }
  return data;
}

function appendAuthorization(headers: Record<string, any> = {}) {
  const token = getToken();
  if (token) {
    headers.Authorization = `Bearer ${token.access_token}`;
  }
  return headers;
}

function register(data: AuthRegister): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    instance
      .post('/api/auth/register', replaceEmptyToNull(data), { headers: appendAuthorization() })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function login(data: AuthLogin): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    instance
      .post('/api/auth/login', data)
      .then((res) => {
        resolve(res);
        setToken(res.data);
        startTimeoutRefreshToken();
      })
      .catch((err) => {
        clearToken();
        reject(err);
      });
  });
}

function refreshToken() {
  return new Promise((resolve, reject) => {
    instance
      .post('/api/auth/refresh', null, { headers: appendAuthorization() })
      .then((res) => {
        resolve(res);
        setToken(res.data);
      })
      .catch((err) => {
        callUnauthorizedAction(err);
        reject(err);
      });
  });
}

function getMe() {
  return new Promise((resolve, reject) => {
    instance
      .post('/api/auth/me', null, { headers: appendAuthorization() })
      .then((res) => {
        resolve(res);
        Me.value = res.data;
        // setToken(res.data);
      })
      .catch((err) => {
        callUnauthorizedAction(err);
        reject(err);
      });
  });
}

function logout() {
  return new Promise((resolve, reject) => {
    instance
      .post('/api/auth/logout', null, { headers: appendAuthorization() })
      .then((res) => {
        resolve(res);
        clearToken();
        Me.value = null;
        unauthorizedAction && unauthorizedAction();
      })
      .catch((err) => {
        callUnauthorizedAction(err);
        reject(err);
      });
  });
}

export {
  login,
  registerUnauthorizedAction,
  register,
  refreshToken,
  getMe,
  appendAuthorization,
  callUnauthorizedAction,
  logout,
};
