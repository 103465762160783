import Vue from 'vue';
import './initCompositionApi';

import BootstrapVue from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import '@/styles/app.scss';
// import '@/assets/sass/style.vue.scss';
import { getMe, registerUnauthorizedAction } from '@/Auth';
import { bvToast, navigation } from '@/bus';
import 'moment/locale/ru';

const configBootstrapVue = {
  BButton: {
    variant: 'primary',
  },
  BModal: {
    okTitle: 'Сохранить',
    cancelTitle: 'Отмена',
    cancelVariant: 'secondary',
  },
  BToast: {
    toaster: 'b-toaster-bottom-right',
    variant: 'danger',
  },
};

Vue.config.productionTip = false;

Vue.use(BootstrapVue, configBootstrapVue);

async function init() {
  try {
    await getMe();
  } catch (e) {}
  const vue = new Vue({
    router,
    render: (h) => h(App),
  }).$mount('#app');
  bvToast.toast = vue.$bvToast.toast;
  navigation.router = vue.$router;
  navigation.route = vue.$route;
}

init();
