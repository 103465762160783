import { ref, Ref } from '@vue/composition-api';
import { Base } from '@/api/Base';

export class Collection<Model = Record<string, any>> extends Base {
  items: Ref<Model[]> = ref([]);

  fetch(params: Record<string, any> = {}, is_public?: boolean) {
    let url = this.routes().fetch;
    if (is_public) url = url.replace('/api/', '/api/public/');
    return this.request({ params, method: 'get', url }, this.items);
  }
}
