import axios from 'axios';

const config = {
  baseURL: process.env.VUE_APP_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const axiosInstance = axios.create(config);

export { axiosInstance };
