import { BModal } from 'bootstrap-vue';
import { ref, Ref } from '@vue/composition-api';

type Modal<T> = {
  modalRef: Ref<BModal | null>;
  fnBackOnShow: null | ((args: T) => void);
  registerOnShow: (fn: (args: T) => void) => void;
  onShow: (args: T) => void;
};

function Modal<T>(): Modal<T> {
  return {
    modalRef: ref(null),
    fnBackOnShow: null,
    registerOnShow(fn) {
      this.fnBackOnShow = fn;
    },
    onShow(args) {
      if (this.modalRef.value) this.modalRef.value.show();
      if (this.fnBackOnShow) this.fnBackOnShow(args);
    },
  };
}

const ModalVideoPreview = Modal<string>();

export { ModalVideoPreview };
