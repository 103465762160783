















import { defineComponent } from '@vue/composition-api';
import { Tests } from '@/api/collections';

export default defineComponent({
  name: 'PublicTests',
  setup() {
    const tests = new Tests();
    tests.fetch({}, true);
    return {
      items: tests.filtered().active,
    };
  },
});
