









































































import { computed, defineComponent } from '@vue/composition-api';
import { createTranslate } from '@/helpers/translate';
import { Test } from '@/api/models';
import QuestionsEdit from '@/components/QuestionsEdit.vue';
import QuestionModal from '@/components/modals/QuestionModal.vue';

const translate = createTranslate({
  title: {
    TestCreate: 'Создание Теста',
    Test: 'Редактирование Теста',
  },
  submit: {
    TestCreate: 'Создать',
    Test: 'Сохранить',
  },
});

export default defineComponent({
  name: 'Test',
  components: {
    QuestionsEdit,
    QuestionModal,
  },
  setup(props, { root }) {
    console.log('setup');
    const title = computed(() => translate(root.$route.name, 'title'));
    const submitTitle = computed(() => translate(root.$route.name, 'submit'));
    const test = new Test({ id: Number(root.$route.params.test_id) });
    if (test.model.value.id) test.fetch();

    function onSubmit() {
      const { id } = test.model.value;
      test.save().then((res) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (!id) root.$router.push({ name: 'Test', params: { test_id: String(res.id) } });
      });
    }

    function onDelete() {
      console.log('delete');
      root.$bvModal
        .msgBoxConfirm(
          'Вы действительно хотите удалить тест? Вмести с ним удалится вся статистика. Изменения не обратимы',
          { okTitle: 'Удалить', okVariant: 'danger' }
        )
        .then((res) => {
          if (res) {
            test.delete().then(() => {
              root.$router.push({ name: 'Tests' });
            });
          }
        });
    }

    function onRefreshQuestions() {
      test.fetchQuestions();
    }

    return {
      submitTitle,
      title,
      model: test.model,
      isFetch: test.isFetch,
      isSaving: test.isSaving,
      isCreate: test.isCreate,
      isFetchQuestions: test.isFetchQuestions,

      onRefreshQuestions,
      onDelete,
      onSubmit,
    };
  },
});
