import { ref, Ref, nextTick } from '@vue/composition-api';

const isFormValidate = ref(false);
const formRef: Ref<HTMLFormElement | null> = ref(null);

async function validate() {
  isFormValidate.value = true;
  await nextTick();
  return new Promise((resolve, reject) => {
    const result = formRef.value?.checkValidity();
    if (result) return resolve(true);
    return reject(Error('Not valid form'));
  });
}

function reset() {
  isFormValidate.value = false;
}

export default {
  isFormValidate,
  formRef,

  reset,
  validate,
};
