function serialize<T = Record<string, any>>(form: HTMLFormElement) {
  const formData = new FormData(form);
  const result: Record<string, any> = {};
  for (const [prop, value] of formData.entries()) {
    result[prop] = value;
  }
  return result as T;
}

export default serialize;
