<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col>
            <h4>Редактирование документа</h4>
            <h6 v-if="isFetch">Идет загрузка данных<b-spinner class="ml-2" small></b-spinner></h6>
          </b-col>
        </b-row>
        <b-card>
          <b-row v-if="!isFetch">
            <b-col>
              <b-form @submit.prevent="">
                <b-form-group label="Название" label-for="input-email">
                  <b-form-input v-model="model.original_name" id="input-email" required />
                </b-form-group>
                <b-form-group label="Ссылка" label-for="input-name">
                  <b-form-input v-model="model.path" id="input-name" disabled />
                </b-form-group>
                <b-button variant="danger" @click="onDelete">Удалить</b-button>
              </b-form>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api';
import { createTranslate } from '@/helpers/translate';
import { Document } from '@/api/models';

export default defineComponent({
  name: 'Document',
  setup(props, { root }) {
    const document = new Document({ id: root.$route.params.document_id });
    document.fetch();

    function onDelete() {
      console.log('delete');
      root.$bvModal
        .msgBoxConfirm('Вы действительно хотите удалить этот документ', {
          okTitle: 'Удалить',
          okVariant: 'danger',
        })
        .then((res) => {
          if (res) {
            document.delete().then(() => {
              root.$router.push({ name: 'Videos' });
            });
          }
        });
    }

    return {
      model: document.model,
      isFetch: document.isFetch,
      isSaving: document.isSaving,
      isCreate: document.isCreate,

      onDelete,
    };
  },
});
</script>
