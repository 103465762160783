





















import { defineComponent, ref, computed, watch, Ref } from '@vue/composition-api';
import moment from 'moment';
import { Statistics, Users, Tests } from '@/api/collections';
import { StatisticModel } from '@/api/Model';

type QueryParams = {
  user_id?: number;
  test_id?: number;
};

const fields = [
  { key: 'test_title', label: 'Название теста' },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'user_full_name',
    label: 'ФИО тестируемого',
  },
  {
    key: 'job_title',
    label: 'Должность',
  },
  {
    key: 'organization',
    label: 'Структурное подразделение',
  },
  {
    key: 'correct_questions_count',
    label: 'Кол-во ответов',
    formatter(val: string, key: string, item: StatisticModel): string {
      return `${item.correct_questions_count + item.wrong_questions_count}(${
        item.wrong_questions_count
      })`;
    },
  },
  {
    key: 'start_time',
    label: 'Начало теста',
    formatter(val: string) {
      return moment(val).format('lll');
    },
  },
];

export default defineComponent({
  name: 'StatisticsView',
  setup() {
    const statistics = new Statistics();
    statistics.fetch();

    const users = new Users();
    users.fetch();
    const tests = new Tests();
    tests.fetch();

    const userId: Ref<null | number> = ref(null);
    const testId: Ref<null | number> = ref(null);

    const queryParams = computed(() => {
      const data: QueryParams = {};
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (userId.value) data.user_id = userId.value;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (testId.value) data.test_id = testId.value;
      return data;
    });

    const usersItems = computed(() => {
      const options = users.items.value.map((user) => ({
        text: `${user.name || ''} ${user.surname || ''} (${user.email})`,
        value: user.id,
      }));
      return [{ text: 'Все', value: null }, ...options];
    });
    const testsItems = computed(() => {
      const options = tests.items.value.map((test) => ({ text: test.title, value: test.id }));
      return [{ text: 'Все', value: null }, ...options];
    });

    watch(queryParams, (val) => {
      statistics.fetch(val);
    });

    return {
      items: statistics.items,
      usersItems,
      testsItems,

      fields,

      userId,
      testId,
    };
  },
});
