











































































import { defineComponent, ref } from '@vue/composition-api';
import { AuthRegister, register } from '@/Auth';
import serialize from '@/helpers/serializeForm';
import cyrillicToTranslit from 'cyrillic-to-translit-js';

export default defineComponent({
  name: 'Register',
  setup(props, { root }) {
    const subomenName = ref('');

    function onSubmit(event: MouseEvent) {
      const target = event.target as HTMLFormElement;
      const data = serialize<AuthRegister>(target);
      register(data)
        .then((res) => {
          console.log('succes', res.data);
          root.$router.push({ name: 'RegisterSuccess', params: { email: res.data.email } });
        })
        .catch((err) => {
          console.log('err.response.status', err.response.status);
          if (err.response.status === 401) {
            // root.$bvToast.toast('Не верный логин или пароль', { title: 'Не авторизован' });
          }
        });
    }

    function onInputCompanyName(event: any) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const translit = cyrillicToTranslit().transform(event, '_');
      subomenName.value = translit
        .match(/[a-z]|[A-Z]|-|_/gm)
        ?.join('')
        .toLowerCase();
    }

    return {
      onSubmit,
      onInputCompanyName,
      subomenName,
    };
  },
});
