import { format, addSeconds } from 'date-fns';

type Token = {
  access_token: string;
  expires_in: number;
  expires_at: number;
  token_type: 'bearer';
};

export function setToken(token: Token) {
  localStorage.setItem(
    'token',
    JSON.stringify({
      ...token,
      expires_at: Number(format(addSeconds(new Date(), token.expires_in), 'T')),
    }),
  );
}

export function getToken() {
  try {
    const token = JSON.parse(localStorage.getItem('token') || '');
    if (token instanceof Object) return token as Token;
    clearToken();
    return false;
  } catch (e) {
    return false;
  }
}

export function clearToken() {
  localStorage.removeItem('token');
}
