import { nextTick, ref, Ref } from '@vue/composition-api';
import { getToken } from '@/Auth/token';
import axios, { Method } from 'axios';
import { callUnauthorizedAction } from '@/Auth';

export type UrlParameters = Record<string, number | string | number>;

export type Props = {
  urlParameters?: UrlParameters;
};

export class Base {
  urlParameters: UrlParameters = {};
  baseUrl: string = process.env.VUE_APP_SERVER_URL;
  isLoading = ref(false);
  headers: Record<string, string> = {};

  constructor({ urlParameters }: Props = {}) {
    if (urlParameters) this.urlParameters = urlParameters;
  }

  getUrlParameters(): UrlParameters {
    return this.urlParameters;
  }
  getURL(url: string) {
    return `${this.baseUrl}${this.getRouteReplacements(url, this.getUrlParameters())}`;
  }

  getRouteReplacements(route: string, urlParameters: UrlParameters) {
    Object.entries(urlParameters).forEach(([prop, value]) => {
      route = route.replace(`{${prop}}`, String(value));
    });
    return route;
  }

  baseHeaders() {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    const token = getToken();
    if (token) {
      headers.Authorization = `Bearer ${token.access_token}`;
    }
    return headers;
  }

  getHeaders(): Record<string, string> {
    return { ...this.baseHeaders(), ...this.headers };
  }

  async request<T>(
    { params = {}, data = {}, method, url }: RequestConfig,
    successResult?: Ref | any,
    loading?: Ref<boolean>
  ): Promise<T> {
    if (method.toLocaleLowerCase() === 'get') this.isLoading.value = true;
    await nextTick();
    return new Promise((resolve, reject) => {
      axios
        .request({ method, url: this.getURL(url), data, params, headers: this.getHeaders() })
        .then((res) => {
          if (successResult) {
            successResult.value = res.data;
          }
          if (method.toLocaleLowerCase() === 'get') this.isLoading.value = false;
          if (loading) loading.value = false;
          resolve(res.data);
        })
        .catch((err) => {
          callUnauthorizedAction(err);
          if (method.toLocaleLowerCase() === 'get') this.isLoading.value = false;
          if (loading) loading.value = false;
          reject(err);
        });
    });
  }

  routes(): Routes {
    return {};
  }
}

type RequestConfig = {
  params?: any;
  data?: any;
  method: Method;
  headers?: Record<string, string>;
  url: string;
};
export type Routes = Record<'fetch' | 'save' | 'update' | 'delete' | string, any>;
