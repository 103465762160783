





































import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import { BIconChevronLeft, BIconChevronRight } from 'bootstrap-vue';
import PublicTestQuestion from '@/components/public/PublicTestQuestion.vue';
import { PublicTest } from '@/api/models';

export default defineComponent({
  name: 'PublicTest',
  components: {
    BIconChevronLeft,
    BIconChevronRight,
    PublicTestQuestion,
  },
  setup(props, { root }) {
    const selectedQuestion = ref(1);

    const test = new PublicTest({ id: Number(root.$route.params.test_id) });
    test.fetch();
    test.fetchQuestions();

    const options = computed(() => {
      return test.model.value?.questions?.map((question, index) => ({
        value: index + 1,
        text: `Вопрос №${index + 1}`,
      }));
    });

    const question = computed(() => test.model.value?.questions?.[selectedQuestion.value - 1]);
    const userAnswered = computed(() => test.model.value?.questions?.filter((item) => item.answer));
    const isComplete = computed(() => {
      const questions = test.model.value?.questions;
      return questions && userAnswered.value && userAnswered.value.length === questions.length;
    });
    const isCorrectTest = computed(() => {
      let correctAnswersCount = 0;
      if (userAnswered.value) {
        const correctQuestions = userAnswered.value.filter((item) => item?.answer?.correct);
        correctAnswersCount = correctQuestions.length;
      }
      return correctAnswersCount >= Number(test.model?.value?.success_count);
    });

    watch(isComplete, (val) => {
      const message = isCorrectTest.value ? 'Тест пройден' : 'Тест не пройден';
      if (val) {
        root.$bvModal
          .msgBoxConfirm(`Тестирование окончено! ${message}.`, {
            okTitle: 'К списку тестов',
            cancelTitle: 'Остаться',
          })
          .then((res) => {
            if (res) {
              root.$router.push({ name: 'StartPage' });
            }
          });
      }
    });

    function onAnswered() {
      if (selectedQuestion.value <= Number(test.model?.value?.questions?.length)) {
        // selectedQuestion.value += 1;
      }
    }

    return {
      selectedQuestion,
      options,

      test: test.model,
      onAnswered,

      question,
    };
  },
});
