



































































import { computed, defineComponent } from '@vue/composition-api';
import { createTranslate } from '@/helpers/translate';
import { User } from '@/api/models';

const translate = createTranslate({
  title: {
    UserInvite: 'Приглашение пользователя',
    User: 'Редактирование пользователя',
  },
  submit: {
    UserInvite: 'Пригласить',
    User: 'Сохранить',
  },
});

export default defineComponent({
  name: 'User',
  setup(props, { root }) {
    console.log('setup');
    const title = computed(() => translate(root.$route.name, 'title'));
    const submitTitle = computed(() => translate(root.$route.name, 'submit'));
    const user = new User(
      root.$route.params.user_id ? { id: Number(root.$route.params.user_id) } : undefined
    );
    if (user.model.value?.id) user.fetch();

    function onSubmit() {
      user.save().then(() => {
        root.$router.push({ name: 'Users' });
      });
    }

    function onDelete() {
      console.log('delete');
      root.$bvModal
        .msgBoxConfirm('Вы действительно хотите удалить приглашения для данного пользователя', {
          okTitle: 'Удалить',
          okVariant: 'danger',
        })
        .then((res) => {
          if (res) {
            user.delete().then(() => {
              root.$router.push({ name: 'Users' });
            });
          }
        });
    }

    return {
      submitTitle,
      title,
      model: user.model,
      isFetch: user.isFetch,
      isSaving: user.isSaving,
      isCreate: user.isCreate,

      onDelete,
      onSubmit,
    };
  },
});
