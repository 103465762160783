import qs from 'qs';

export function getYoutubeVideoUrl(urlVideo: string | undefined) {
  if (
    urlVideo &&
    urlVideo.indexOf('https://www.youtube.com/watch') === 0 &&
    urlVideo.match(/(\?).*/gm)
  ) {
    const parseQuery = urlVideo.match(/(\?).*/gm);
    if (parseQuery) {
      const parameters = qs.parse(parseQuery[0].replace('?', ''));
      if (parameters.v) {
        return `https://www.youtube.com/embed/${parameters.v}`;
      }
    }
  }
  return null;
}

export function checkIsPublicCompany() {
  const domains = location.host.split('.');
  return domains.length === 3 && domains[0] === 'public';
}
