





















import { defineComponent } from '@vue/composition-api';
import { Tests } from '@/api/collections';

export default defineComponent({
  name: 'UserTests',
  setup() {
    const tests = new Tests();
    tests.fetch();

    return { items: tests.filtered().active };
  },
});
