<template>
  <div>
    <div class="d-flex mb-2">
      <h4>Список тестов</h4>
      <router-link :to="{ name: 'TestCreate' }" class="btn btn-outline-primary ml-auto">
        Добавить
      </router-link>
    </div>
    <b-card>
      <b-table-lite :fields="fields" :items="items" striped>
        <template #cell(active)="{ value }">
          <h5>
            <b-badge :variant="value.variant">{{ value.title }}</b-badge>
          </h5>
        </template>
        <template #cell(actions)="{ item }">
          <router-link :to="{ name: 'Test', params: { test_id: item.id } }"
            >Редактировать</router-link
          >
        </template>
      </b-table-lite>
    </b-card>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { Tests } from '@/api/collections';

const fields = [
  { key: 'title', label: 'Название' },
  { key: 'description', label: 'Описание' },
  {
    key: 'active',
    label: 'Статус',
    formatter: (val) =>
      val
        ? { title: 'Опубликован', variant: 'success' }
        : { title: 'Не опублкован', variant: 'secondary' },
  },
  { key: 'actions', label: '' },
];

export default defineComponent({
  name: 'Tests',
  setup() {
    const tests = new Tests();
    tests.fetch();

    return {
      fields,
      items: tests.items,
    };
  },
});
</script>
