





























import { logout } from '@/Auth';
import VideoPreviewModal from '@/components/modals/VideoPreviewModal.vue';
import ProfileDropdown from '@/components/ProfileDropdown.vue';

export default {
  name: 'AdminLayout',
  components: {
    VideoPreviewModal,
    ProfileDropdown,
  },
  setup() {
    return {
      logout,
    };
  },
};
