

































import { defineComponent, Ref, ref } from '@vue/composition-api';
import { ModalVideoPreview } from '@/store/modals';
import { getYoutubeVideoUrl } from '@/helpers';

export default defineComponent({
  name: 'VideoPreviewModal',
  setup() {
    const src: Ref<null | string> = ref(null);
    const isVisible = ref(false);

    function onShow(urlVideo: string) {
      src.value = getYoutubeVideoUrl(urlVideo);
    }
    ModalVideoPreview.registerOnShow(onShow);

    return {
      src,
      isVisible,
      modalRef: ModalVideoPreview.modalRef,
    };
  },
});
