
















































import { defineComponent, PropType } from '@vue/composition-api';
import { Question, QuestionModel } from '@/api/models';
import QuestionModal from '@/components/modals/QuestionModal.vue';
import QuestionModalRef from '@/components/modals/QuestionModalRef';
import { BIconPencil, BIconTrash, BIconPlay } from 'bootstrap-vue';
import { ModalVideoPreview } from '@/store/modals';

export default defineComponent({
  name: 'QuestionsEdit',
  components: {
    QuestionModal,
    BIconPencil,
    BIconTrash,
    BIconPlay,
  },
  props: {
    questions: {
      type: Array as PropType<QuestionModel[]>,
      required: true,
    },
    isFetch: {
      type: Boolean,
    },
    testId: {
      type: Number,
    },
  },
  setup(props, { root, emit }) {
    function onAdd() {
      QuestionModalRef.onShow(root.$route.params.test_id);
    }
    function onEdit(question: QuestionModel) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      QuestionModalRef.onShow(root.$route.params.test_id, question);
    }

    function onDelete(data: QuestionModel) {
      console.log('onDelete');
      root.$bvModal
        .msgBoxConfirm('Вы действительно хотите удалить вопрос', {
          title: 'Удаление вопроса',
          okTitle: 'Удалить',
          okVariant: 'danger',
        })
        .then((res) => {
          if (res) {
            const question = new Question(data);
            question.delete().then(() => {
              emit('refresh');
            });
          }
        });
    }

    function onOpenVideo(url: string) {
      ModalVideoPreview.onShow(url);
    }

    return {
      onAdd,
      onEdit,
      onDelete,
      onOpenVideo,
    };
  },
});
