import { computed, ref, Ref } from '@vue/composition-api';
import { Base, Props, UrlParameters } from '@/api/Base';
import { defaults } from 'lodash';

export type ToOptional<T> = {
  [P in keyof T]?: T[P];
};

export class Model<Model extends Object & { id?: number }> extends Base {
  model = ref({} as Model);
  isSaving = ref(false);
  isCreate = computed(() => !Number(this.model.value?.id));
  isFetch = ref(false);
  statusMethod = computed(() => (this.isCreate.value ? 'create' : 'update'));

  getUrlParameters(): UrlParameters {
    return {
      id: Number(this.model.value.id),
    };
  }

  constructor(model?: Model | { id: number }, props?: Props) {
    super(props);
    this.model.value = defaults(model, this.defaults());
    if (model?.id) {
      this.urlParameters.id = model.id;
    }
  }

  async fetch(params: Record<string, any> = {}): Promise<Model & { id: number }> {
    return this.request<Model & { id: number }>(
      { params, method: 'get', url: this.routes().fetch },
      this.model,
      this.isFetch
    );
  }

  defaults(): Model {
    return {} as Model;
  }

  async save(data?: ToOptional<Model>) {
    console.log('save model', this.model.value);
    this.isSaving.value = true;
    if (Number(this.model.value?.id)) {
      return this.update(data);
    }
    return this.request<Model & { id: number }>(
      { data: { ...this.model.value, ...data }, method: 'post', url: this.routes().save },
      this.model,
      this.isSaving
    );
  }

  update(data?: ToOptional<Model>) {
    return this.request<Model & { id: number }>(
      { data: { ...this.model.value, ...data }, method: 'put', url: this.routes().update },
      this.model,
      this.isSaving
    );
  }

  setModel(data: Model) {
    this.model.value = { ...this.model.value, ...data };
  }

  async delete(params: Record<string, any> = {}) {
    return this.request<Model & { id: number }>(
      { params, method: 'delete', url: this.routes().delete },
      this.model
    );
  }

  reset() {
    this.model.value = this.defaults();
  }
}

export type StatisticModel = {
  company_id: number;
  correct_questions_count: number;
  email: string;
  end_time: string;
  job_title: string;
  organization: string;
  start_time: string;
  test_id: number;
  test_session_id: number;
  test_title: string;
  user_full_name: string;
  user_id: number;
  wrong_questions_count: number;
};
