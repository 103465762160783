<template>
  <div>
    <div class="d-flex mb-2">
      <h4>Список пользователей</h4>
      <router-link :to="{ name: 'UserInvite' }" class="btn btn-outline-primary ml-auto">
        Пригласить
      </router-link>
    </div>
    <b-card>
      <b-table :fields="fields" :items="items" striped>
        <template #cell(active)="{ value }">
          <h5>
            <b-badge :variant="value.variant">{{ value.title }}</b-badge>
          </h5>
        </template>
        <template #cell(actions)="{ item }">
          <router-link :to="{ name: 'User', params: { user_id: item.id } }"
            >Редактировать</router-link
          >
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { Users } from '@/api/collections';

const fields = [
  { key: 'email', label: 'Название', sortable: true },
  { key: 'pivot.name', label: 'Имя', sortable: true },
  { key: 'pivot.surname', label: 'Фамилия', sortable: true },
  { key: 'pivot.patronymic', label: 'Отчество', sortable: true },
  { key: 'pivot.job_title', label: 'Должность', sortable: true },
  { key: 'actions', label: '' },
];

export default defineComponent({
  name: 'Users',
  setup() {
    const users = new Users();
    users.fetch();

    return {
      fields,
      items: users.items,
    };
  },
});
</script>
