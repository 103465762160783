






































import axios from 'axios';
import { defineComponent, ref, Ref } from '@vue/composition-api';
import { selectedCompany } from '@/store/company';
import { callUnauthorizedAction } from '@/Auth';

export default defineComponent({
  name: 'Documents',
  setup(props, { root }) {
    const documents: Ref<Array<Blob | File>> = ref([]);
    const isSaving: Ref<boolean> = ref(false);

    function getHeaders() {
      const headers: Record<string, string> = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      };
      const token = JSON.parse(localStorage.getItem('token') || '');
      if (token) {
        headers.Authorization = `Bearer ${token.access_token}`;
      }
      return headers;
    }

    function onSubmit() {
      isSaving.value = true;
      const data = new FormData();
      data.append('company_id', String(selectedCompany.value?.id));
      documents.value.forEach((document) => {
        data.append('documents[]', document);
      });

      const url = `${process.env.VUE_APP_SERVER_URL}/api/documents`;
      axios
        .post(url, data, { headers: getHeaders() })
        .then(() => {
          root.$router.push({ name: 'Videos' });
        })
        .catch((err) => callUnauthorizedAction(err))
        .finally(() => {
          isSaving.value = false;
        });
    }

    return {
      documents,
      isSaving,

      onSubmit,
    };
  },
});
