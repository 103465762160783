



























import { defineComponent } from '@vue/composition-api';
import VideoPreviewModal from '@/components/modals/VideoPreviewModal.vue';
import ProfileDropdown from '@/components/ProfileDropdown.vue';
import { selectedCompany } from '@/store/company';

export default defineComponent({
  name: 'PersonalLayout',
  components: {
    VideoPreviewModal,
    ProfileDropdown,
  },
  setup() {
    return {
      selectedCompany,
    };
  },
});
