import { computed } from '@vue/composition-api';
import { Collection } from '@/api/Collection';
import {
  CompanyModel,
  QuestionModel,
  TestModel,
  TestSessionModel,
  UserModel,
  VideoModel,
  DocumentModel,
} from '@/api/models';
import { Routes, UrlParameters } from '@/api/Base';
import { selectedCompany } from '@/store/company';

class Companies extends Collection<CompanyModel> {
  routes(): Routes {
    return {
      fetch: '/api/companies',
    };
  }
}

class Tests extends Collection<TestModel> {
  getUrlParameters(): UrlParameters {
    return {
      company_id: String(selectedCompany.value?.id),
    };
  }
  filtered() {
    return {
      active: computed(() => this.items.value?.filter((test) => test.active)),
    };
  }

  routes(): Routes {
    return {
      fetch: '/api/companies/{company_id}/tests',
    };
  }
}

class Questions extends Collection<QuestionModel> {
  getUrlParameters(): UrlParameters {
    return {
      company_id: String(selectedCompany.value?.id),
    };
  }

  routes(): Routes {
    return {
      fetch: '/api/companies/{company_id}/tests',
    };
  }
}

class Users extends Collection<UserModel> {
  getUrlParameters(): UrlParameters {
    return {
      company_id: String(selectedCompany.value?.id),
    };
  }

  routes(): Routes {
    return {
      fetch: '/api/companies/{company_id}/users',
    };
  }
}

class Videos extends Collection<VideoModel> {
  getUrlParameters(): UrlParameters {
    return {
      company_id: String(selectedCompany.value?.id),
    };
  }

  routes(): Routes {
    return {
      fetch: '/api/companies/{company_id}/videos',
    };
  }
}

class Statistics extends Collection {
  getUrlParameters(): UrlParameters {
    return {
      company_id: String(selectedCompany.value?.id),
    };
  }

  routes(): Routes {
    return {
      fetch: '/api/companies/{company_id}/statistic',
    };
  }
}

class Documents extends Collection<DocumentModel> {
  getUrlParameters(): UrlParameters {
    return {
      company_id: String(selectedCompany.value?.id),
    };
  }

  routes(): Routes {
    return {
      fetch: '/api/companies/{company_id}/documents',
    };
  }
}

export { Companies, Tests, Users, Videos, Statistics, Documents };
