


















































































































import { defineComponent } from '@vue/composition-api';
import { BIconCardChecklist, BIconCollectionPlay } from 'bootstrap-vue';

// const logo = require('../a')

export default defineComponent({
  name: 'StartPage',
  components: { BIconCardChecklist, BIconCollectionPlay },
});
