






































import { defineComponent, ref, PropType, computed } from '@vue/composition-api';
import { AnswerModel, QuestionModel, TestSessionModel } from '@/api/models';
import { Model } from '@/api/Model';
import { getYoutubeVideoUrl } from '@/helpers';

export default defineComponent({
  name: 'UserTestQuestion',
  props: {
    answer: {
      type: Object as PropType<AnswerModel>,
    },
    question: {
      type: Object as PropType<QuestionModel>,
      required: true,
    },
    session: {
      type: Object as PropType<Model<TestSessionModel>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedAnswer = ref();

    const isAnswered = computed(() => !!props.answer?.id);
    const videoSrc = computed(() => getYoutubeVideoUrl(props.question.video_url));

    if (props.answer) {
      selectedAnswer.value = props.answer.id;
    }

    function onToAnswer() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      props.session.save({ answer_id: Number(selectedAnswer.value) }).then(() => {
        emit('answered');
      });
    }

    return {
      isAnswered,
      selectedAnswer,

      videoSrc,

      onToAnswer,
    };
  },
});
