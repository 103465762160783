












































import { defineComponent } from '@vue/composition-api';
import { BIconPlay } from 'bootstrap-vue';
import { ModalVideoPreview } from '@/store/modals';
import { Videos, Documents } from '@/api/collections';
import { DocumentModel } from '@/api/models';

const videosFields = [
  { key: 'title', label: 'Название', sortable: true },
  { key: 'url', label: 'Сылка' },
  { key: 'actions', label: '' },
];
const documentsFields = [{ key: 'original_name', label: 'Название' }];

export default defineComponent({
  name: 'Users',
  components: {
    BIconPlay,
  },
  setup() {
    const videos = new Videos();
    videos.fetch();

    function onShowVideo(url: string) {
      ModalVideoPreview.onShow(url);
    }

    const documents = new Documents();
    documents.fetch();

    function onDeleteDocument(event: Event, document: DocumentModel) {
      console.log('event', event);
      event.preventDefault();
      console.log('document', document);
    }

    return {
      videosFields,
      documentsFields,
      videos: videos.items,
      documents: documents.items,

      onShowVideo,
      onDeleteDocument,
    };
  },
});
