


















import { defineComponent, Ref, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'PublicMaterials',
  setup(props, { root }) {
    const currentTab: Ref<string> = ref(String(root.$route.name));

    function onChangeTab(route: string) {
      if (root.$route.name === route) return;

      currentTab.value = route;
      root.$router.push({ name: route });
    }

    return {
      currentTab,

      onChangeTab,
    };
  },
});
