













































import { defineComponent, computed, ref } from '@vue/composition-api';
import { BIconTrash } from 'bootstrap-vue';
import { createTranslate } from '@/helpers/translate';

import { Question, QuestionModel } from '@/api/models';
import formValidation from '@/helpers/formValidation';

import modal from './QuestionModalRef';

const translate = createTranslate({
  title: {
    create: 'Создание Вопроса',
    update: 'Редактирование Вопроса',
  },
  ok: {
    create: 'Создать',
    update: 'Сохранить',
  },
});

export default defineComponent({
  name: 'QuestionModal',
  components: {
    BIconTrash,
  },
  setup(props, { emit }) {
    const question = new Question();
    modal.onShow = (testId: number, data?: QuestionModel) => {
      question.reset();
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (question.model.value) question.model.value.test_id = testId;
      formValidation.reset();
      if (modal.modalRef.value) modal.modalRef.value.show();
      if (data?.id) question.setModel(data);
      if (data?.id) question.fetch(); // TODO remove this
    };

    const titles = computed(() => ({
      title: translate(question.statusMethod.value, 'title'),
      ok: translate(question.statusMethod.value, 'ok'),
    }));

    function onSubmit() {
      formValidation
        .validate()
        .then(() => {
          question.save().then((res) => {
            modal.close();
            emit('success', res);
          });
        })
        .catch();
    }

    function onAddAnswer() {
      // eslint-disable-next-line no-unused-expressions
      question.model.value?.answers.push({ correct: false, body: '' });
    }

    function onCorrectAnswer(answer: { body: string; correct: boolean }) {
      question.model.value?.answers.forEach((item) => {
        item.correct = false;
      });
      answer.correct = true;
    }

    function onDeleteAnswer(index: number) {
      if (question.model.value?.answers) {
        const { answers } = question.model.value;
        answers.splice(index, 1);
        const findCorrectAnswer = answers.find(({ correct }) => correct);
        if (!findCorrectAnswer && answers.length) answers[0].correct = true;
      }
    }

    return {
      ...modal,
      ...formValidation,
      model: question.model,
      titles,

      onCorrectAnswer,
      onSubmit,
      onAddAnswer,
      onDeleteAnswer,
    };
  },
});
