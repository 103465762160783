// eslint-disable-next-line max-classes-per-file
import { Model, ToOptional } from '@/api/Model';
import { Routes, UrlParameters } from '@/api/Base';
import { selectedCompany } from '@/store/company';
import { navigation } from '@/bus';
import { ref } from '@vue/composition-api';

export interface CompanyModel {
  id?: number;
  title: string;
  domain: string;
  is_public?: 1 | 0;
  users?: {
    name?: string;
    surname?: string;
    patronymic?: string;
    organization?: string;
    job_title?: string;
    pivot: {
      admin: 1 | 0;
    };
  }[];
}

export class Company extends Model<CompanyModel> {
  defaults(): CompanyModel {
    return {
      title: '',
      domain: '',
    };
  }

  routes(): Routes {
    return {
      fetch: '/api/companies/{id}',
      save: '/api/companies/',
      update: '/api/companies/{id}',
      delete: '/api/companies/{id}',
    };
  }
}

export interface TestModel {
  id?: number;
  company_id: number;
  title: string;
  description?: string;
  active?: boolean;
  success_count?: number;
  questions?: QuestionModel[];
  test_session?: TestSessionModel;
}

export class Test extends Model<TestModel> {
  isFetchQuestions = ref(false);

  defaults(): TestModel {
    return {
      title: '',
      company_id: Number(selectedCompany.value?.id),
      active: false,
      description: '',
    };
  }

  routes(): Routes {
    return {
      fetch: '/api/tests/{id}',
      save: '/api/tests/',
      update: '/api/tests/{id}',
      delete: '/api/tests/{id}',
    };
  }

  // @ts-ignore
  update(data: ToOptional<TestModel>) {
    console.log('update');
    return new Promise((resolve, reject) => {
      this.request<TestModel & { id: number }>(
        {
          url: this.routes().fetch,
          data: { ...this.model.value, ...data },
          method: 'put',
        },
        null,
        this.isSaving
      )
        .then((res) => {
          this.model.value = { ...res, questions: this.model.value.questions };
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  async fetchQuestions(): Promise<QuestionModel[]> {
    return new Promise((resolve, reject) => {
      if (!this.model.value.questions) {
        this.model.value = { ...this.model.value, questions: [] };
      }
      this.request<QuestionModel[]>(
        {
          method: 'get',
          url: `${this.routes().fetch}/questions`,
          headers: this.getHeaders(),
        },
        this.model.value.questions,
        this.isFetchQuestions
      )
        .then((res) => {
          if (this.model.value) this.model.value.questions = res;
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
}

export class PublicTest extends Test {
  routes(): Routes {
    return {
      fetch: '/api/public/tests/{id}',
    };
  }
}

export interface UserModel {
  id?: number;
  email: string;
  name?: string;
  surname?: string;
  patronymic?: string;
  organization?: string;
  job_title?: string;
}

export class User extends Model<UserModel> {
  getUrlParameters(): UrlParameters {
    return {
      company_id: Number(selectedCompany.value?.id),
      id: Number(this.model.value?.id),
    };
  }

  defaults(): UserModel {
    return {
      email: '',
    };
  }

  routes(): Routes {
    return {
      fetch: '/api/companies/{company_id}/users/{id}',
      save: '/api/companies/{company_id}/users/',
      update: '/api/companies/{company_id}/users/{id}',
      delete: '/api/companies/{company_id}/users/{id}',
    };
  }
}

export interface AnswerModel {
  id?: number;
  body: string;
  correct: boolean;
  question_id?: number;
}
export interface QuestionModel {
  answer?: AnswerModel;
  id?: number;
  test_id: number;
  body: string;
  title?: string;
  video_url?: string;
  answers: AnswerModel[];
}

export class Question extends Model<QuestionModel> {
  getUrlParameters(): UrlParameters {
    return {
      id: Number(this.model.value?.id),
    };
  }

  defaults(): QuestionModel {
    return {
      test_id: 0,
      body: '',
      answers: [
        {
          body: '',
          correct: true,
        },
      ],
    };
  }

  routes(): Routes {
    return {
      fetch: '/api/questions/{id}',
      save: '/api/questions/',
      update: '/api/questions/{id}',
      delete: '/api/questions/{id}',
    };
  }
}

export interface TestSessionModel {
  id?: number;
  test_id: number;
  answer_id?: number;
  finished?: 1 | 0;
  answers?: AnswerModel[];
}

export class TestSession extends Model<TestSessionModel> {
  getUrlParameters(): UrlParameters {
    return {
      id: Number(this.model.value?.id),
    };
  }

  defaults(): TestSessionModel {
    return {
      test_id: 0,
    };
  }

  routes(): Routes {
    return {
      fetch: '/api/test-sessions/{id}',
      save: '/api/test-sessions/',
      update: '/api/test-sessions/{id}',
      delete: '/api/test-sessions/{id}',
    };
  }

  // @ts-ignore
  update(data?: ToOptional<TestSessionModel>): Promise<AnswerModel> {
    return new Promise((resolve, reject) => {
      this.request<AnswerModel[]>(
        { data: { ...this.model.value, ...data }, method: 'put', url: this.routes().update },
        null,
        this.isSaving
      )
        .then((answers) => {
          const answered = answers.find((answer) => answer.id === data?.answer_id);
          if (answered) {
            this.model.value.answers?.push(answered);
          }
          console.log('answered', answered);
          resolve(answered);
        })
        .catch((err) => resolve(err));
    });
  }

  close() {
    console.log('this.model.value', this.model.value);
    return this.request({
      url: `/api/test-sessions/${this.model.value?.id}/close`,
      method: 'POST',
    });
  }
}

export interface VideoModel {
  id?: number;
  title: string;
  company_id: number;
  url: string;
}

export class Video extends Model<VideoModel> {
  getUrlParameters(): UrlParameters {
    return {
      id: Number(this.model.value?.id),
    };
  }

  defaults(): VideoModel {
    return {
      title: '',
      company_id: Number(selectedCompany.value?.id),
      url: '',
    };
  }

  routes(): Routes {
    return {
      fetch: '/api/videos/{id}',
      save: '/api/videos/',
      update: '/api/videos/{id}',
      delete: '/api/videos/{id}',
    };
  }
}

export interface DocumentModel {
  id?: number;
  company_id: number;
  created_at: string;
  original_name: string;
  path: string;
  updated_at: string;
}

export class Document extends Model<DocumentModel> {
  getUrlParameters(): UrlParameters {
    return {
      id: Number(this.model.value?.id),
    };
  }

  defaults(): DocumentModel {
    return {
      company_id: Number(selectedCompany.value?.id),
      created_at: '',
      original_name: '',
      path: '',
      updated_at: '',
    };
  }

  routes(): Routes {
    return {
      fetch: '/api/documents/{id}',
      update: '/api/documents/{id}',
      delete: '/api/documents/{id}',
    };
  }
}
