import { ref, Ref } from '@vue/composition-api';
import { BModal } from 'bootstrap-vue';

const QuestionModalRef = {
  modalRef: <Ref<null | BModal>>ref(null),
  onShow(...args: any) {
    this.modalRef.value?.show();
  },
  close() {
    this.modalRef.value?.hide();
  },
};

export default QuestionModalRef;
