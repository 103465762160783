











import { defineComponent } from '@vue/composition-api';
import { Me } from '@/store/auth';
import { logout } from '@/Auth';

export default defineComponent({
  name: 'ProfileDropdown',
  setup(props, { root }) {
    function onChangeCompany() {
      root.$router.push({ name: 'Companies' });
    }
    return {
      Me,

      onChangeCompany,
      logout,
    };
  },
});
