import { BvToastShortcutMethod } from 'bootstrap-vue';
import { Route } from 'vue-router';
import { VueRouter } from 'vue-router/types/router';

export const bvToast = {
  toast: null as null | BvToastShortcutMethod,
};

export const navigation = {
  router: null as null | VueRouter,
  route: null as null | Route,
};
