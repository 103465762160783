









































import { computed, defineComponent } from '@vue/composition-api';
import { createTranslate } from '@/helpers/translate';
import { Video } from '@/api/models';

const translate = createTranslate({
  title: {
    VideoCreate: 'Добавление видеоматериала',
    Video: 'Редактирование видеоматериала',
  },
  submit: {
    VideoCreate: 'Добавить',
    Video: 'Сохранить',
  },
});

export default defineComponent({
  name: 'Video',
  setup(props, { root }) {
    console.log('setup');
    const title = computed(() => translate(root.$route.name, 'title'));
    const submitTitle = computed(() => translate(root.$route.name, 'submit'));
    const video = new Video(
      root.$route.params.video_id ? { id: Number(root.$route.params.video_id) } : undefined
    );
    if (video.model.value?.id) video.fetch();

    function onSubmit() {
      video.save().then(() => {
        root.$router.push({ name: 'Videos' });
      });
    }

    function onDelete() {
      console.log('delete');
      root.$bvModal
        .msgBoxConfirm('Вы действительно хотите удалить этот видеоматериал', {
          okTitle: 'Удалить',
          okVariant: 'danger',
        })
        .then((res) => {
          if (res) {
            video.delete().then(() => {
              root.$router.push({ name: 'Videos' });
            });
          }
        });
    }

    return {
      submitTitle,
      title,
      model: video.model,
      isFetch: video.isFetch,
      isSaving: video.isSaving,
      isCreate: video.isCreate,

      onDelete,
      onSubmit,
    };
  },
});
