<template>
  <div>
    <b-row>
      <b-col md="3" lg="2" sm="5" v-for="document in documents.items.value" :key="document.id">
        <a
          :href="document.path"
          target="_blank"
          class="text-decoration-none"
          style="color: #181c32"
        >
          <b-card class="mb-3 d-flex align-items-center text-center p-2">
            <BIconFileEarmarkArrowDown font-scale="7" style="color: #787878" class="mb-3" />
            <h5>{{ document.original_name }}</h5>
          </b-card>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { BIconFileEarmarkArrowDown } from 'bootstrap-vue';
import { Documents } from '@/api/collections';

export default defineComponent({
  name: 'PublicDocuments',
  components: {
    BIconFileEarmarkArrowDown,
  },
  setup() {
    const documents = new Documents();
    documents.fetch({}, true);

    return {
      documents,
    };
  },
});
</script>
