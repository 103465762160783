import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';

import AuthLayout from '@/layouts/AuthLayout.vue';
import AdminLayout from '@/layouts/AdminLayout.vue';
import PersonalLayout from '@/layouts/PersonalLayout.vue';
import PublicLayout from '@/layouts/PublicLayout.vue';

import StartPage from '@/views/StartPage.vue';

import Login from '@/views/auth/Login.vue';
import Register from '@/views/auth/Register.vue';
import RegisterSuccess from '@/views/auth/RegisterSuccess.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';

import page404 from '@/views/404.vue';

import Statistics from '@/views/admin/Statistics.vue';
import User from '@/views/admin/User.vue';
import Users from '@/views/admin/Users.vue';
import Video from '@/views/admin/Video.vue';
import Document from '@/views/admin/Document.vue';
import Documents from '@/views/admin/Documents.vue';
import Videos from '@/views/admin/Videos.vue';
import Test from '@/views/admin/Test.vue';
import Tests from '@/views/admin/Tests.vue';
import Settings from '@/views/admin/Settings.vue';

import Companies from '@/views/personal/Companies.vue';
import UserTests from '@/views/personal/UserTests.vue';
import UserTest from '@/views/personal/UserTest.vue';
import UserTestQuestion from '@/views/personal/UserTestQuestion.vue';
import Materials from '@/views/personal/Materials.vue';
import UserVideos from '@/views/personal/UserVideos.vue';
import UserDocuments from '@/views/personal/UserDocuments.vue';

import PublicTest from '@/views/public/PublicTest.vue';
import PublicTests from '@/views/public/PublicTests.vue';
import PublicVideos from '@/views/public/PublicVideos.vue';
import PublicMaterials from '@/views/public/PublicMaterials.vue';
import PublicDocuments from '@/views/public/PublicDocuments.vue';

import { isAuth } from '@/store/auth';
import { Company, CompanyModel } from '@/api/models';
import { selectedCompany } from '@/store/company';

import { checkIsPublicCompany } from '@/helpers';
import { axiosInstance } from '@/api/request';

Vue.use(VueRouter);

const AuthGuard = function (to: Route, from: Route, next: NavigationGuardNext) {
  if (isAuth.value) {
    return next();
  }
  return next({ name: 'Auth' });
};

function AuthSelectCompanyGuard(to: Route, from: Route, next: NavigationGuardNext) {
  if (isAuth.value) {
    const company = new Company({ id: Number(to.params.id) });
    return company
      .fetch()
      .then((res) => {
        selectedCompany.value = res;
        next();
      })
      .catch(() => {
        next({ name: 'Page404' });
      });
  }
  return next({ name: 'Auth' });
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'StartPage',
    component: StartPage,
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthLayout,
    redirect: '/auth/login',
    beforeEnter(to, from, next) {
      console.log('checkIsPublicCompany', checkIsPublicCompany());
      if (isAuth.value) {
        next({ name: 'Companies' });
      }
      next();
    },
    children: [
      {
        path: '/auth/login',
        name: 'Login',
        component: Login,
      },
      {
        path: '/auth/register',
        name: 'Register',
        component: Register,
      },
      {
        path: '/auth/register-success',
        name: 'RegisterSuccess',
        component: RegisterSuccess,
      },
      {
        path: '/auth/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
      },
    ],
  },
  {
    path: '/admin/companies/:id',
    name: 'Admin',
    component: AdminLayout,
    beforeEnter: AuthSelectCompanyGuard,
    redirect: '/admin/companies/:id/tests',
    children: [
      {
        path: 'tests',
        name: 'Tests',
        component: Tests,
      },
      {
        path: 'tests/create',
        name: 'TestCreate',
        component: Test,
      },
      {
        path: 'tests/:test_id',
        name: 'Test',
        component: Test,
      },
      {
        path: 'videos',
        name: 'Videos',
        component: Videos,
      },
      {
        path: 'videos/create',
        name: 'VideoCreate',
        component: Video,
      },
      {
        path: 'videos/:video_id',
        name: 'Video',
        component: Video,
      },
      {
        path: 'documents/:document_id',
        name: 'Document',
        component: Document,
      },
      {
        path: 'create-documents',
        name: 'DocumentsCreate',
        component: Documents,
      },
      {
        path: 'users',
        name: 'Users',
        component: Users,
      },
      {
        path: 'users/invite',
        name: 'UserInvite',
        component: User,
      },
      {
        path: 'users/:user_id',
        name: 'User',
        component: User,
      },
      {
        path: 'statistics',
        name: 'Statistics',
        component: Statistics,
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings,
      },
    ],
  },
  {
    path: '/personal/companies/:id',
    name: 'Personal',
    component: PersonalLayout,
    beforeEnter: AuthSelectCompanyGuard,
    redirect: '/personal/companies/:id/tests',
    children: [
      {
        path: 'tests',
        name: 'UserTests',
        component: UserTests,
      },
      {
        path: 'tests/:test_id',
        name: 'UserTest',
        component: UserTest,
        children: [
          {
            path: 'question/:questionIndex',
            name: 'UserTestQuestion',
            component: UserTestQuestion,
          },
        ],
      },
      {
        path: 'materials',
        name: 'Materials',
        component: Materials,
        redirect: '/personal/companies/:id/materials/videos',
        children: [
          {
            path: 'videos',
            name: 'UserVideos',
            component: UserVideos,
          },
          {
            path: 'documents',
            name: 'UserDocuments',
            component: UserDocuments,
          },
        ],
      },
    ],
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Companies,
    beforeEnter: AuthGuard,
  },
  {
    path: '/public',
    name: 'Public',
    redirect: '/public/tests',
    component: PublicLayout,
    beforeEnter: (to, from, next) => {
      axiosInstance
        .get(`/api/public/company-by-domain`, { params: { domain: 'public' } })
        .then((res) => {
          selectedCompany.value = res.data as CompanyModel;
          next();
        })
        .catch((err) => {
          console.log('err');
        });
    },
    children: [
      {
        path: '/public/tests',
        name: 'PublicTests',
        component: PublicTests,
      },
      {
        path: '/public/tests/:test_id',
        name: 'PublicTest',
        component: PublicTest,
      },
      {
        path: '/public/materials',
        name: 'PublicMaterials',
        component: PublicMaterials,
        redirect: '/public/materials/videos',
        children: [
          {
            path: 'videos',
            name: 'PublicVideos',
            component: PublicVideos,
          },
          {
            path: 'documents',
            name: 'PublicDocuments',
            component: PublicDocuments,
          },
        ],
      },
    ],
  },
  {
    path: '/404',
    name: 'Page404',
    component: page404,
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
