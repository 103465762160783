<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { registerUnauthorizedAction } from '@/Auth';

export default defineComponent({
  name: 'App',
  mounted() {
    registerUnauthorizedAction(() => {
      this.$router.push({ name: 'Auth' });
    });
  },
});
</script>
