<template>
  <b-row>
    <b-col md="5">
      <b-card
        style="cursor: pointer"
        v-for="video in videos.items.value"
        :key="video.id"
        @click="onSelectVideo(video)"
        class="mb-3"
        :class="{ 'bg-info': selectedVideo.id === video.id }"
      >
        <h5>{{ video.title }}</h5>
      </b-card>
    </b-col>
    <b-col md="7">
      <iframe
        height="315"
        width="560"
        frameborder="0"
        :src="videoSrc"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </b-col>
  </b-row>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api';
import { getYoutubeVideoUrl } from '@/helpers';
import { Videos } from '@/api/collections';

export default defineComponent({
  name: 'UserVideos',
  setup() {
    const videos = new Videos();
    videos.fetch();

    const selectedVideo = ref({});
    const videoSrc = computed(() => getYoutubeVideoUrl(selectedVideo.value?.url));

    function onSelectVideo(video) {
      selectedVideo.value = video;
    }

    return {
      videoSrc,
      videos,
      selectedVideo,

      onSelectVideo,
    };
  },
});
</script>
